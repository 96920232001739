<template>
    <recess-modal
        :show-modal="isModalVisible"
        @close="close"
    >
        <template slot="modal-title">
            <h3>{{ PROVIDER.LegalAgreement.Title }}</h3>
        </template>

        <template slot="modal-body">
            <p>{{ PROVIDER.LegalAgreement.Description }}</p>

            <!-- download PDF link -->
            <a
                :href="cookieStatementDownloadLink"
                target="_blank"
            >
                Cookie statement EduMS.pdf
            </a>

            <form @submit.prevent="submitHandler">
                <recess-checkbox-input
                    v-model="legalAgreementConsent"
                    name="legal-agreements"
                    :label-text="PROVIDER.LegalAgreement.Consent.Label"
                    variant="secondary"
                    class="my-2 qa-legal-agreement-consent"
                />

                <div class="d-flex justify-content-between align-items-center"> 
                    <recess-button
                        variant="primary"
                        type="submit"
                        :title="BUTTON_TEXT.submit"
                        class=" ml-4 qa-legal-agreements-submit-button"
                    />
                </div>
            </form>
        </template>
    </recess-modal>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { BUTTON_TEXT } from '@/../../shared/constants/constantsGeneral.json'
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'
import { legalAgreementError } from '../../../../shared/constants/validationMessageHelper'
 

export default {
    name:  'LegalAgreementPopupComponent', 
    props: {
        isModalVisible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            BUTTON_TEXT,
            PROVIDER,
            legalAgreementConsent: false,
            // this hardcoded link per environment should be changed after storage is ready in server
            cookieStatementDownloadLink: process.env.VUE_APP_COOKIE_STATEMENT
        }
    }, 
    methods: { 
        close() {
            this.$emit('close')
        },
        submitHandler() {
            this.showConsentRequiredError()
            this.$emit('submit', this.legalAgreementConsent)
        },
        showConsentRequiredError() { 
            const toastNotification = {
                type: 'error',
                message: legalAgreementError
            }             

            if (!this.legalAgreementConsent) {
                this.$store.dispatch('toastNotificationModule/add', toastNotification, {
                    root: true
                }) 
            }
        }
    }
}
</script>